import {
	CommonModule,
	NgClass,
	NgSwitchCase,
	NgSwitchDefault,
} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
	InfiniteScrollCustomEvent,
	IonAvatar,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonList,
} from '@ionic/angular/standalone';
import { Subject, takeUntil } from 'rxjs';

import { LocalTimePipe } from '../../../../shared/pipes/local-time.pipe';
import { Notification } from '@app/core/models/notification.model';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { RouterLink } from '@angular/router';
import { TimeAgoPipe } from '../../../../shared/pipes/time-ago.pipe';

@Component({
	selector: 'app-infinite-scroll-notify',
	templateUrl: './infinite-scroll-notify.component.html',
	styleUrls: ['./infinite-scroll-notify.component.scss'],
	standalone: true,
	imports: [
		IonList,
		IonItem,
		IonAvatar,
		IonInfiniteScroll,
		IonInfiniteScrollContent,
		LocalTimePipe,
		TimeAgoPipe,
		CommonModule,
		RouterLink,
		NgSwitchCase,
		NgSwitchDefault,
		NgClass,
	],
})
export class InfiniteScrollNotifyComponent implements OnInit {
	page: number = 1;
	size: number = 10;

	private _unsubscribe = new Subject<void>();
	public notifications: Notification[] = [];
	constructor(
		private _notificationService: NotificationService,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit() {
		this.getNotificationsUnRead();
		this.invokeReceiveNotification();
	}

	invokeReceiveNotification() {
		this._notificationService
			.onEvent('_ReceiveNotification')
			.subscribe((notification) => {
				this.notifications.unshift(notification);
			});
	}

	getSanitizedHtml(html: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	onIonInfinite(event: InfiniteScrollCustomEvent) {
		this.getNotificationsUnRead(event);
	}

	notificationRefresh(event: any) {
		this.page = 1;
		this.getNotificationsUnRead();
	}

	markReadNotification(notification: Notification) {
		if (notification.isRead) return;
		notification.isRead = true;

		this._notificationService.readNotification(notification.id).subscribe();
	}

	getNotificationsUnRead(
		infiniteEvent: InfiniteScrollCustomEvent | null = null,
	) {
		this._notificationService
			.getNotifications(this.page, this.size)
			.pipe(takeUntil(this._unsubscribe))
			.subscribe({
				next: (newNotifications) => {
					if (newNotifications.length > 0) this.page++;

					this.notifications = this.notifications.concat(newNotifications);

					if (infiniteEvent) infiniteEvent.target.complete();
				},
			});
	}

	getRoute(url: string): string {
		const [path] = url.split('?');
		return path; // Solo devuelve la ruta base
	}

	getQueryParams(url: string): any {
		const [, queryString] = url.split('?');
		if (!queryString) return {};

		return queryString.split('&').reduce((acc: any, param) => {
			const [key, value] = param.split('=');
			acc[key] = value;
			return acc;
		}, {});
	}
}

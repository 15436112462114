@if (notificationNumber > 0) {
  <div
    class="absolute w-5 h-5 font-bold text-white bg-red-500 border-2 border-white rounded-full top-0 left-4 -end-0"
    style="
      font-size: 10px !important;
      padding: 1px !important;
      text-align: center;
    "
  >
    {{ notificationNumber }}
  </div>
}

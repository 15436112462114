import { Observable, catchError, of, switchMap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@app/core/models/notification.model';
import { ResponseModel } from '@app/core/models/response.model';
import { SignalRService } from '../signalR/signal-r.service';
import { environment } from '@env/environment';

@Injectable()
export class NotificationService {
	URL: string = environment.apiUrl + 'notifications/';

	constructor(
		private _httpClient: HttpClient,
		private signalRService: SignalRService,
	) {}

	public startConnection(): void {
		this.signalRService.startConnectionHub('notifications');
	}

	onEvent(eventName: string): Observable<Notification> {
		return this.signalRService.invokeEvent(eventName);
	}

	/**
	 * Get list from notifications
	 * @returns {Observable<Notification[]>} Observable of notifications
	 */
	getNotifications(
		page: number = 1,
		size: number = 10,
	): Observable<Notification[]> {
		return this._httpClient
			.get(`${this.URL}get-notifications?Page=${page}&Size=${size}`)
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<Notification[]>;
					return of(res.data);
				}),
				catchError((error) => {
					throw error;
				}),
			);
	}

	readNotification(id: number) {
		return this._httpClient
			.post(this.URL + 'read-notification', {
				notificationId: id,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
				catchError((error) => {
					throw error;
				}),
			);
	}
}


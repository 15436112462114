import { Component, Input } from '@angular/core';

@Component({
  selector: 'notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss'],
  standalone: true,
})
export class NotificationBadgeComponent {
  @Input() notificationNumber: number = 0;
  constructor() {}
}

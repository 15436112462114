import * as signalR from '@microsoft/signalr';

import { AuthenticationService } from '../authentication/authentication.service';
import { Howl } from 'howler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class SignalRService {
	private hubConnection!: signalR.HubConnection;
	private socket = environment.socket;
	private thenable!: Promise<void>;

	constructor(private _authService: AuthenticationService) {}
	startConnectionHub(hub: string) {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(this.socket + hub, {
				accessTokenFactory: async () => this._authService.accessToken,
				skipNegotiation: true,
				transport: signalR.HttpTransportType.WebSockets,
			})
			.withAutomaticReconnect()
			// .configureLogging(signalR.LogLevel.Debug)
			.build();
		this.start();
	}
	private start() {
		this.thenable = this.hubConnection.start();
		this.thenable
			.then(() => console.info('Connection started!'))
			.catch((err) => {
				console.error('Error while establishing connection :(', err);
				console.log(err.message);
			});
	}

	invokeEvent(eventName: string): Observable<any> {
		return new Observable((observer) => {
			this.hubConnection.on(eventName, (data) => {
				var sound = new Howl({
					src: ['assets/sounds/notification.wav'],
				});
				sound.play();

				observer.next(data);
			});
		});
	}
}


import { CommonModule, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
	InfiniteScrollCustomEvent,
	IonAvatar,
	IonContent,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonList,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/angular/standalone';
import { Subject, takeUntil } from 'rxjs';

import { InfiniteScrollNotifyComponent } from './components/infinite-scroll-notify/infinite-scroll-notify.component';
import { LocalTimePipe } from '@app/shared/pipes/local-time.pipe';
import { Notification } from '@app/core/models/notification.model';
import { NotificationBadgeComponent } from '../../shared/components/icons/notification-badge/notification-badge.component';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { RouterLink } from '@angular/router';
import { TimeAgoPipe } from '@app/shared/pipes/time-ago.pipe';
import { addIcons } from 'ionicons';
import { notificationsOutline } from 'ionicons/icons';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		RouterLink,
		NgSwitchCase,
		NgSwitchDefault,
		IonRefresher,
		IonRefresherContent,
		TimeAgoPipe,
		LocalTimePipe,
		NotificationBadgeComponent,
		IonIcon,
		InfiniteScrollNotifyComponent,
		IonContent,
	],
})
export class NotificationsComponent implements OnInit {
	@Input() isDropdown: boolean = false;
	public notifications: Notification[] = [];
	newNotifications: number = 0;
	private _unsubscribe = new Subject<void>();

	constructor(
		private _notificationService: NotificationService,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit() {
		addIcons({ notificationsOutline });
		this.invokeReceiveNotification();
		this.updateNotificationsNumber();
	}

	invokeReceiveNotification() {
		this._notificationService
			.onEvent('_ReceiveNotification')
			.subscribe((notification) => {
				this.notifications.unshift(notification);
				this.updateNotificationsNumber();
			});
	}
	private updateNotificationsNumber() {
		this.newNotifications = this.notifications.filter((x) => !x.isRead).length;
	}

	ngOnDestroy() {
		this._unsubscribe.next();
		this._unsubscribe.complete();
		this._unsubscribe.unsubscribe();
	}
}

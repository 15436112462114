<div class="divide-y divide-gray-100">
	@if (notifications.length > 0) {
		<ion-list>
			<!-- List Notifications -->
			@for (notification of notifications; track notification.id) {
				<a
					[routerLink]="getRoute(notification.redirectTo)"
					[queryParams]="getQueryParams(notification.redirectTo)"
					(click)="markReadNotification(notification)"
					[ngClass]="{
						'bg-primary-50': !notification.isRead,
						'bg-white': notification.isRead
					}"
					class="flex px-4 py-3 border-b hover:bg-gray-50"
				>
					<div class="flex-shrink-0">
						<img
							class="rounded-full w-11 h-11"
							src="assets/imgs/memoji.jpeg"
							alt="Leslie image"
						/>
						<div
							class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 border border-white rounded-full bg-primary-700 dark:border-gray-700"
						>
							<svg
								class="w-3 h-3 text-white"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
								></path>
								<path
									d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
								></path>
							</svg>
						</div>
						<div *ngSwitch="notification.notificationType">
							<!-- Comment Type -->
							<ng-template
								*ngSwitchCase="'Comment'"
								class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-green-400 border border-white rounded-full dark:border-gray-700"
							>
								<svg
									class="w-3 h-3 text-white"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
										clip-rule="evenodd"
									></path>
								</svg>
							</ng-template>

							<!-- Schedule Routine type -->
							<ng-template
								*ngSwitchCase="'FreightStatus'"
								class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 border border-white rounded-full bg-primary-700 dark:border-gray-700"
							>
								<svg
									class="w-3 h-3 text-white"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
									></path>
									<path
										d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
									></path>
								</svg>
							</ng-template>

							<div
								class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 border border-white rounded-full bg-primary-700 dark:border-gray-700"
							>
								<svg
									class="w-3 h-3 text-white"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"
									></path>
									<path
										d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
									></path>
								</svg>
							</div>
						</div>
					</div>
					<div class="w-full pl-3">
						<div [innerHTML]="getSanitizedHtml(notification.title)"></div>
						<div [innerHTML]="getSanitizedHtml(notification.content)"></div>
						<div
							class="text-xs font-medium text-primary-700 dark:text-primary-400"
						>
							{{ notification.createdAt | localTime | timeAgo }}
						</div>
					</div>
				</a>
			}
		</ion-list>
		<ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
			<ion-infinite-scroll-content></ion-infinite-scroll-content>
		</ion-infinite-scroll>
	} @else {
		<div class="text-center font-bold text-md p-8">No hay notifications</div>
	}
</div>

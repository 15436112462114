@if (isDropdown) {
  <button
    (click)="newNotifications = 0"
    data-dropdown-toggle="notification-dropdown-1"
    class="hidden sm:block relative p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700"
  >
    <span class="sr-only">View notifications</span>
    <div
      *ngIf="newNotifications > 0"
      class="absolute w-5 h-5 font-bold text-white bg-red-500 border-2 border-white rounded-full top-0 -left-1 -end-2"
      style="
        font-size: 10px !important;
        padding: 1px !important;
        text-align: center;
      "
    >
      {{ newNotifications }}
    </div>
    <ion-icon name="notifications-outline" />
  </button>
}
@if (isDropdown) {
  <ion-content
    id="notification-dropdown-1"
    class="hidden overflow-y-scroll absolute z-20 z-50 max-w-md h-[42rem] flex-end space-around mx-auto sm:mx-4 right-0 top-0 flex flex-col text-base list-none bg-white divide-y divide-gray-100 rounded-b-lg shadow-lg border-2 no-scrollbar"
  >
    <app-infinite-scroll-notify class="max-h-[42rem]" />
  </ion-content>
} @else {
  <div class="w-full bg-white divide-y divide-gray-100">
    <app-infinite-scroll-notify />
  </div>
}
